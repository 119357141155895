import React from "react";
import "../css/whyus.css";


function WhyUs(){

    return (
        <section id="whyus" className="anchor py-5">
            <div className="why-us-container">
                <div className="whyus-section">
                    <div className="whyus-main-info-container">
                        <div className="whyus-info">
                            <span className="span-stuff">Why us?</span>
                            <h2>Why do business with us?</h2>
                            <span className="the_info">
                                <p>Choose ALSHAN for a Promise of Excellence! Our reliability and unwavering commitment set us apart. With spare equipment and critical
                                   parts ready, we ensure timely deliveries. Enjoy competitive prices on our premium products, thanks to fixed agreements with raw
                                   material suppliers. At ALSHAN, your satisfaction is our constant commitment.

                                </p>
                            </span>
                        </div>
                    </div>
                </div>



            </div>

        </section>

    )
}

export default WhyUs;