import React from "react";
import "../css/main_slider.css";
import cocoa_img from "../img/Cocoa_images.png"

function Landing(props){

    return (
        <header id="header" className="anchor">
            {props.children}
            {/*<div className="d-flex align-items-center"*/}
            {/*     style={{height:"100vh", backgroundColor: "rosybrown", backgroundPosition:"center", backgroundSize: "cover"}}>*/}
            {/*    <div className="text-center w-100 text-white">*/}
            {/*        <h1>Heading</h1>*/}
            {/*        <h2 className="font-weight-normal">Heading</h2>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="carousel slide" data-ride="carousel" id="carousel-1">
                <div className="carousel-inner" role="listbox">
                    <div className="carousel-item active">
                        <div className="jumbotron pulse animated hero-nature carousel-hero">

                        </div>
                        <div className="item_text">
                            <div className="">
                                <div className="hero-main">
                                    <div className="greeting">
                                        Welcome to AlshanAgro
                                    </div>
                                    <h1 className="hero-title">
                                        We convert agro-waste into valuable resources.
                                    </h1>
                                    <p className="">
                                        Green Innovations in Every Fiber! Whether whole or ground cocoa bean shells, or top-notch palm kernel shells – we've got your
                                        projects in farming and industry covered. Elevate with our irresistible natural solutions!
                                    </p>
                                    <span>
                                        <button className="btn btn-warning"><a className="button_learn" href="#products">Learn more</a></button>
                                    </span>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Landing;