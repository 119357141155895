import React from "react";
import footerImg from "../img/AlshanAgrologo.png";
import "../css/footer.css";



function Footer(){

    let copyDate = new Date().getFullYear();
    
    return(
    <footer id="footer" className="anchor text-black py-5 text-center" style={{backgroundColor: "#F3F6F5"}}>
        <div className="container text-muted py-4 py-lg-5">
            <div><img src={footerImg} className="alshanLogo footerLogo" alt=""/></div>

            <div className="infoFooter">
                <p className="footerInfo">Converting agricultural by-products into valuable resources for agricultural and industrial purposes.</p>
            </div>

            <ul className="list-inline footerNav">
                <li className="list-inline-item me-4"><a className="footerNavInfo" href="#about">About Us</a></li>
                <span className="circle"> </span>
                <li className="list-inline-item me-4"><a className="footerNavInfo" href="#products">Our Products</a></li>
                <span className="circle"> </span>
                <li className="list-inline-item"><a className="footerNavInfo" href="#whyus">Why Us</a></li>
                <span className="circle"> </span>
                <li className="list-inline-item me-4"><a className="footerNavInfo" href="#team">Our Team</a></li>
                <span className="circle"> </span>
                <li className="list-inline-item me-4"><a className="footerNavInfo" href="#gallery">Gallery</a></li>
            </ul>

            <div className="contactDetails">
                <p className="contactInfo">Email: alshanagroltd@gmail.com</p>
                <p className="contactInfo">Warehouse Address: 1st Light Industrial Area, Off Sekondi-Takoradi Road, Takoradi</p>

            </div>
            <p className="mb-0 copystuff">AlshanAgro © {copyDate}. All Rights Reserved. Designed and developed by <a target="_blank" className="dela_name" href="https://www.delalipui.com/" rel="noreferrer">Dela</a> and Richard Alipui</p>
        </div>
    </footer>
        
    )
}

export default Footer;