import React from "react";
import "../css/team.css";
import samuel from "../img/samuel.png";
import susan from "../img/susan.png";
import steven from "../img/steven.png";
import alice from "../img/alice.png";
import emma from "../img/Emmanuel.png";


function Team(){

    return(
        <section id="team" className="anchor py-5">
            <div className="container py-4 py-xl-5">
                <div className="row mb-4 mb-lg-5">
                    <div className="col-md-8 col-xl-6 text-center mx-auto team_titles">
                        <h2>Who we are</h2>
                        <h4>Our management team</h4>
                    </div>
                </div>
                <div className="row row-cols-md-5 team_main">
                    <div className="col-sm-6">
                        <div className="team_box border-0 shadow-none">
                            <div className="text-center d-flex flex-column align-items-center p-0">
                                <img className="rounded-circle mb-3 fit-cover" width="130" height="130" src={steven} alt="Steven Kwesi Amakye"/>
                                <h5 className="fw-bold mb-0"><strong>Stephen Kwesi Amakye, FMP</strong></h5>
                                <p className="text-muted mb-2 roles">Director, Technical Services</p>

                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="team_box border-0 shadow-none">
                            <div className="text-center d-flex flex-column align-items-center p-0">
                                <img className="rounded-circle mb-3 fit-cover" width="130" height="130" src={samuel} alt="Samuel Lamptey"/>
                                <h5 className="fw-bold mb-0"><strong>Ing. Samuel O. Lamptey</strong></h5>
                                <p className="text-muted mb-2 roles">Director, Finance</p>

                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="team_box border-0 shadow-none">
                            <div className="text-center d-flex flex-column align-items-center p-0">
                                <img className="rounded-circle mb-3 fit-cover" width="130" height="130" src={alice} alt="Alice Amakye"/>
                                <h5 className="fw-bold mb-0"><strong>Francesca Alice Enchill Amakye</strong></h5>
                                <p className="text-muted mb-2 roles">Director, Operations</p>

                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="team_box border-0 shadow-none">
                            <div className="text-center d-flex flex-column align-items-center p-0">
                                <img className="rounded-circle mb-3 fit-cover" width="130" height="130" src={susan} alt="Susan Shanu Lamptey"/>
                                <h5 className="fw-bold mb-0"><strong>Susan Shanu Lamptey</strong></h5>
                                <p className="text-muted mb-2 roles">Director, Sales and Marketing</p>

                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="team_box border-0 shadow-none">
                            <div className="text-center d-flex flex-column align-items-center p-0">
                                <img className="rounded-circle mb-3 fit-cover" width="130" height="130" src={emma} alt="Emmanuel Baah Tei"/>
                                <h5 className="fw-bold mb-0" style={{maxWidth: "140px"}}><strong>Emmanuel Baah Tei</strong></h5>
                                <p className="text-muted mb-2 roles">Manager, Shipping and Logistics</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Team;