import React from "react";
import "../css/products.css"
import palm_shells from "../img/Palm_kernel_shells.jpg"
import cocoa_bean from "../img/Cocoa_bean_shells.png"

function Products(){

    const [width, setWidth] = React.useState(window.innerWidth);
    const breakpoint = 800;
    React.useEffect(() => {
        const handleResizeWindow = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleResizeWindow);
        return () => {
            window.removeEventListener("resize", handleResizeWindow);
        };
    },[]);

    if (width > breakpoint){

        return(
            <section id="products" className="anchor py-5">

                <div className="row product-heading">
                    <div className="col-md-12 text-center">
                        <span  className="small-heading">Our products</span>
                        <h2>We source agro-based bio fibres</h2>
                        <span className="para">
                        <p style={{color: "#3D3D3D"}}>Dive into our product selection: Whole or ground cocoa bean shells and premium palm kernel shells. Discover
                                                      the natural essence for your agricultural and industrial pursuits.
                        </p>
                    </span>

                    </div>
                </div>

                <BigScreen/>

            </section>
        )

    } else {

        return (
            <section id="products" className="anchor py-5">

                <div className="row product-heading">
                    <div className="col-md-12 text-center">
                        <span  className="small-heading">Our products</span>
                        <h2>We source agro-based bio fibres</h2>
                        <span className="para">
                        <p style={{color: "#3D3D3D"}}>Dive into our product selection: Whole or ground cocoa bean shells and premium palm kernel shells. Discover the
                                                      natural essence for your agricultural and industrial pursuits.
                        </p>
                    </span>

                    </div>
                </div>

                <SmallScreen/>
            </section>
        )

    }
}

function BigScreen(){
    return (

        <div className="container-fluid product-container">
            <div className="row product-main">
                <div className="col-md-6">
                    <div className="product_text">
                        <h2>Grinded Cocoa Bean Shells</h2>
                        <p style={{color: "#3D3D3D"}}>We expertly transform cocoa shells into premium ground form. cocoa bean shells not only elevate farming practices as
                                                      nutrient-rich mulch but also serve as a premium supplement in animal feed.
                        </p>
                    </div>
                </div>
                <div className="col-md-6 prod_img_main">
                    <div className="product_img_class">
                        <img src={cocoa_bean} className="prod_img" alt="cocoa beans"/>
                    </div>
                </div>
            </div>

            <div className="row product-palm">
                <div className="col-md-6">
                    <div className="product_img_class">
                        <img src={palm_shells} className="prod_img" alt="palm shells"/>

                    </div>
                </div>
                <div className="col-md-6 shells_img">
                    <div className="product_text">
                        <h2>Palm Kernel Shells</h2>
                        <p style={{color: "#3D3D3D"}}>Elevate your industry with Palm Kernel Shells – a versatile biomass fuel. Unlock electricity, heat, liquid fuels,
                                                      and valuable chemicals while reducing sulfur dioxide and carbon emissions. Perfect for eco-conscious industries
                                                      transitioning to sustainable solutions, they not only promote environmental stewardship but also offer cost-effective
                                                      advantages, making them the preferred choice in the market. </p>
                    </div>
                </div>
            </div>
        </div>


    )
}


function SmallScreen(){

    return (
        <div className="container-fluid product-container">
            <div className="row product-main">
                <div className="col-md-6">
                    <div className="product_text">
                        <h2>Grinded Cocoa Bean Shells</h2>
                        <p style={{color: "#3D3D3D"}}>We expertly transform cocoa shells into premium ground form. cocoa bean shells not only elevate farming practices as
                                                      nutrient-rich mulch but also serve as a premium supplement in animal feed.
                        </p>
                    </div>
                </div>
                <div className="col-md-6 prod_img_main">
                    <div className="product_img_class">
                        <img src={cocoa_bean} className="prod_img" alt="cocoa beans"/>
                    </div>
                </div>
            </div>

            <div className="row product-palm">
                <div className="col-md-6 shells_img">
                    <div className="product_text">
                        <h2>Palm Kernel Shells</h2>
                        <p style={{color: "#3D3D3D"}}>Elevate your industry with Palm Kernel Shells – a versatile biomass fuel. Unlock electricity, heat, liquid fuels,
                                                      and valuable chemicals while reducing sulfur dioxide and carbon emissions. Perfect for eco-conscious industries
                                                      transitioning to sustainable solutions, they not only promote environmental stewardship but also offer cost-effective
                                                      advantages, making them the preferred choice in the market. </p>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="product_img_class">
                        <img src={palm_shells} className="prod_img" alt="palm shells"/>

                    </div>
                </div>
            </div>
        </div>
    )


}


export default Products;
export {SmallScreen};