import React, {Fragment} from "react";
import "../css/gallery.css";
// import w1 from "../img/W1.JPG";
// import w2 from "../img/W2.JPG";
// import w3 from "../img/W3.JPG";
// import w4 from "../img/W4.JPG";
// import w5 from "../img/W5.JPG";
// import w6 from "../img/W6.JPG";
// import w7 from "../img/W7.JPG";
import w8 from "../img/W8.JPG";
import w9 from "../img/W9.JPG";
import w10 from "../img/W10.JPG";
import w11 from "../img/W11.JPG";
import w12 from "../img/W12.JPG";
import r1 from "../img/R1.jpg";
import r2 from "../img/R2.jpg";
import r3 from "../img/R3.jpg";
import r4 from "../img/R4.jpg";
import r5 from "../img/R5.jpg";
import r6 from "../img/R8.jpg";
import r7 from "../img/R9.jpg";
import r8 from "../img/R10.jpg";
import r9 from "../img/R11.jpg";
import r10 from "../img/R12.jpg";
import r11 from "../img/R13.jpg";
import r12 from "../img/R14.jpg";
import r13 from "../img/R15.jpg";
import r14 from "../img/R16.jpg";
import r15 from "../img/R17.jpg";
import r16 from "../img/R18.jpg";
import r17 from "../img/R19.jpg";
import r18 from "../img/R20.jpg";
import r19 from "../img/R21.jpg";
import r20 from "../img/R22.jpg";
import r21 from "../img/R23.jpg";
import r22 from "../img/R24.jpg";
import r23 from "../img/R25.jpg";
import r24 from "../img/R26.jpg";
import r25 from "../img/R29.jpg";
import r26 from "../img/R30.jpg";
import r27 from "../img/R31.jpg";
import r28 from "../img/R32.jpg";
import r29 from "../img/R33.jpg";
import r30 from "../img/R34.jpg";


function Gallery(){

    return(
        <Fragment>
            <section id="filtr-gallery">
                <div className="container" id="gallery">
                    <h1 className="text-center gall">GALLERY</h1>
                    {/*<div className="filtr-controls"><span className="active" data-filter="all">all </span></div>*/}
                    <div className="row filtr-container">

                        {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}

                        <div className="col-6 col-sm-4 col-md-3 filtr-item" data-category="1"><a href={w8} target="_blank" rel="noreferrer"><img
                            className="img-fluid" src={w8} loading="lazy" alt="image"/></a></div>
                        <div className="col-6 col-sm-4 col-md-3 filtr-item" data-category="1"><a href={w9} target="_blank" rel="noreferrer"><img
                            className="img-fluid" src={w9} loading="lazy" alt="image"/></a></div>
                        <div className="col-6 col-sm-4 col-md-3 filtr-item" data-category="1"><a href={w10} target="_blank" rel="noreferrer"><img
                            className="img-fluid" src={w10} loading="lazy" alt="image"/></a></div>
                        <div className="col-6 col-sm-4 col-md-3 filtr-item" data-category="1"><a href={w11} target="_blank" rel="noreferrer"><img
                            className="img-fluid" src={w11} loading="lazy" alt="image"/></a></div>
                        <div className="col-6 col-sm-4 col-md-3 filtr-item" data-category="1"><a href={w12} target="_blank" rel="noreferrer"><img
                            className="img-fluid" src={w12} loading="lazy" alt="image"/></a></div>
                        <div className="col-6 col-sm-4 col-md-3 filtr-item" data-category="2"><a href={r1} target="_blank" rel="noreferrer"><img
                            className="img-fluid" src={r1} loading="lazy" alt="image"/></a></div>
                        <div className="col-6 col-sm-4 col-md-3 filtr-item" data-category="2"><a href={r2} target="_blank" rel="noreferrer"><img
                            className="img-fluid" src={r2} loading="lazy" alt="image"/></a></div>
                        <div className="col-6 col-sm-4 col-md-3 filtr-item" data-category="2"><a href={r3} target="_blank" rel="noreferrer"><img
                            className="img-fluid" src={r3} loading="lazy" alt="image"/></a></div>
                        <div className="col-6 col-sm-4 col-md-3 filtr-item" data-category="2"><a href={r4} target="_blank" rel="noreferrer"><img
                            className="img-fluid" src={r4} loading="lazy" alt="image"/></a></div>
                        <div className="col-6 col-sm-4 col-md-3 filtr-item" data-category="2"><a href={r5} target="_blank" rel="noreferrer"><img
                            className="img-fluid" src={r5} loading="lazy" alt="image"/></a></div>
                        <div className="col-6 col-sm-4 col-md-3 filtr-item" data-category="2"><a href={r6} target="_blank" rel="noreferrer"><img
                            className="img-fluid" src={r6} loading="lazy" alt="image"/></a></div>
                        <div className="col-6 col-sm-4 col-md-3 filtr-item" data-category="2"><a href={r7} target="_blank" rel="noreferrer"><img
                            className="img-fluid" src={r7} loading="lazy" alt="image"/></a></div>
                        <div className="col-6 col-sm-4 col-md-3 filtr-item" data-category="2"><a href={r8} target="_blank" rel="noreferrer"><img
                            className="img-fluid" src={r8} loading="lazy" alt="image"/></a></div>
                        <div className="col-6 col-sm-4 col-md-3 filtr-item" data-category="2"><a href={r9} target="_blank" rel="noreferrer"><img
                            className="img-fluid" src={r9} loading="lazy" alt="image"/></a></div>
                        <div className="col-6 col-sm-4 col-md-3 filtr-item" data-category="2"><a href={r10} target="_blank" rel="noreferrer"><img
                            className="img-fluid" src={r10} loading="lazy" alt="image"/></a></div>
                        <div className="col-6 col-sm-4 col-md-3 filtr-item" data-category="2"><a href={r11} target="_blank" rel="noreferrer"><img
                            className="img-fluid" src={r11} loading="lazy" alt="image"/></a></div>
                        <div className="col-6 col-sm-4 col-md-3 filtr-item" data-category="2"><a href={r12} target="_blank" rel="noreferrer"><img
                            className="img-fluid" src={r12} loading="lazy" alt="image"/></a></div>
                        <div className="col-6 col-sm-4 col-md-3 filtr-item" data-category="2"><a href={r13} target="_blank" rel="noreferrer"><img
                            className="img-fluid" src={r13} loading="lazy" alt="image"/></a></div>
                        <div className="col-6 col-sm-4 col-md-3 filtr-item" data-category="2"><a href={r14} target="_blank" rel="noreferrer"><img
                            className="img-fluid" src={r14} loading="lazy" alt="image"/></a></div>
                        <div className="col-6 col-sm-4 col-md-3 filtr-item" data-category="2"><a href={r15} target="_blank" rel="noreferrer"><img
                            className="img-fluid" src={r15} loading="lazy" alt="image"/></a></div>
                        <div className="col-6 col-sm-4 col-md-3 filtr-item" data-category="2"><a href={r16} target="_blank" rel="noreferrer"><img
                            className="img-fluid" src={r16} loading="lazy" alt="image"/></a></div>

                        <div className="col-6 col-sm-4 col-md-3 filtr-item" data-category="2"><a href={r17} target="_blank" rel="noreferrer"><img
                            className="img-fluid" src={r17} loading="lazy" alt="image"/></a></div>
                        <div className="col-6 col-sm-4 col-md-3 filtr-item" data-category="2"><a href={r18} target="_blank" rel="noreferrer"><img
                            className="img-fluid" src={r18} loading="lazy" alt="image"/></a></div>
                        <div className="col-6 col-sm-4 col-md-3 filtr-item" data-category="2"><a href={r19} target="_blank" rel="noreferrer"><img
                            className="img-fluid" src={r19} loading="lazy" alt="image"/></a></div>
                        <div className="col-6 col-sm-4 col-md-3 filtr-item" data-category="2"><a href={r20} target="_blank" rel="noreferrer"><img
                            className="img-fluid" src={r20} loading="lazy" alt="image"/></a></div>
                        <div className="col-6 col-sm-4 col-md-3 filtr-item" data-category="2"><a href={r21} target="_blank" rel="noreferrer"><img
                            className="img-fluid" src={r21} loading="lazy" alt="image"/></a></div>
                        <div className="col-6 col-sm-4 col-md-3 filtr-item" data-category="2"><a href={r22} target="_blank" rel="noreferrer"><img
                            className="img-fluid" src={r22} loading="lazy" alt="image"/></a></div>
                        <div className="col-6 col-sm-4 col-md-3 filtr-item" data-category="2"><a href={r23} target="_blank" rel="noreferrer"><img
                            className="img-fluid" src={r23} loading="lazy" alt="image"/></a></div>
                        <div className="col-6 col-sm-4 col-md-3 filtr-item" data-category="2"><a href={r24} target="_blank" rel="noreferrer"><img
                            className="img-fluid" src={r24} loading="lazy" alt="image"/></a></div>
                        <div className="col-6 col-sm-4 col-md-3 filtr-item" data-category="2"><a href={r25} target="_blank" rel="noreferrer"><img
                            className="img-fluid" src={r25} loading="lazy" alt="image"/></a></div>
                        <div className="col-6 col-sm-4 col-md-3 filtr-item" data-category="2"><a href={r26} target="_blank" rel="noreferrer"><img
                            className="img-fluid" src={r26} loading="lazy" alt="image"/></a></div>
                        <div className="col-6 col-sm-4 col-md-3 filtr-item" data-category="2"><a href={r27} target="_blank" rel="noreferrer"><img
                            className="img-fluid" src={r27} loading="lazy" alt="image"/></a></div>
                        <div className="col-6 col-sm-4 col-md-3 filtr-item" data-category="2"><a href={r28} target="_blank" rel="noreferrer"><img
                            className="img-fluid" src={r28} loading="lazy" alt="image"/></a></div>
                        <div className="col-6 col-sm-4 col-md-3 filtr-item" data-category="2"><a href={r29} target="_blank" rel="noreferrer"><img
                            className="img-fluid" src={r29} loading="lazy" alt="image"/></a></div>
                        <div className="col-6 col-sm-4 col-md-3 filtr-item" data-category="2"><a href={r30} target="_blank" rel="noreferrer"><img
                            className="img-fluid" src={r30} loading="lazy" alt="image"/></a></div>

                    </div>

                </div>
            </section>
        </Fragment>
    )
}


export default Gallery;