import React from "react";
import "../css/core_values.css"
import trust_img from "../img/trust.png"
import integrity_img from "../img/Vector.png"
import teamwork_img from "../img/teamwork.png"
import hardwork_img from "../img/hardwork.png"
import excellence_img from "../img/excellence.png"
function CoreValues(){

    return (
        <section id="section2" className="anchor py-5">
            <div className="container core_values_main">
                <h1 className="text-center stand-for">What we stand for</h1>
                <div className="container">
                    <div className="row text-center c-values">
                        <div className="col-md-12 cv-heading">
                            <h2>Our core values</h2>
                        </div>
                    </div>

                    <div className="features-boxed">
                        <div className="container">
                            <div className="row justify-content-center features">
                                <div className="col-sm-6 col-md-5 col-lg-4 item">
                                    <div className="box"><img src={trust_img} className="icon" alt=""/>
                                        <h3 className="name">Trust</h3>
                                        <p className="description">Trust is fundamental to our business relationships with customers, employees, and partners. </p>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-5 col-lg-4 item">
                                    <div className="box"><img src={integrity_img} className="icon" alt=""/>
                                        <h3 className="name">Integrity</h3>
                                        <p className="description"> We operate with transparency, fairness, and adherence to ethical standards.</p>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-5 col-lg-4 item">
                                    <div className="box"><img src={teamwork_img} className="icon" alt=""/>
                                        <h3 className="name">Teamwork</h3>
                                        <p className="description">At ALSHAN, we value teamwork and encourage effective communication, respect and support among our employees.</p>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-5 col-lg-4 item">
                                    <div className="box"><img src={hardwork_img} className="icon" alt=""/>
                                        <h3 className="name">Hardwork</h3>
                                        <p className="description">Our value of hard work means that we are dedicated, persistent, and equipped with a strong work ethic to achieve success in all our endeavors. At ALSHAN, productivity is a culture. </p>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-5 col-lg-4 item">
                                    <div className="box"><img src={excellence_img} className="icon" alt=""/>
                                        <h3 className="name">Excellence</h3>
                                        <p className="description">We take pride in our work and strive for perfection with a high degree of attention to detail.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default CoreValues;