import {Fragment} from "react";
// import {Route, Routes} from "react-router-dom";
import Landing from "./components/Landing";
import Nav from "./components/Nav";
import About from "./components/About";
import Products from "./components/Products";
import Team from "./components/Team";
import WhyUs from "./components/WhyUs";
import Footer from "./components/Footer";
import Gallery from "./components/Gallery";

function App() {
  return (
    <Fragment>
        {/*<Routes>*/}
        {/*    <Route path="gallery" element={<Gallery/>}/>*/}
        {/*</Routes>*/}
      <Landing>
        <Nav/>
      </Landing>
        <About/>
        <Products/>
        <WhyUs/>
        <Team/>
        <Gallery/>
        <Footer/>
    </Fragment>

  );
}

export default App;
