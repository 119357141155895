import React, {Fragment} from "react";
import '../css/Nav.css'
import mainLogo from "../img/AlshanAgrologo.png";

function Nav (){
    return(
        <Fragment>
        <div className="fixed-top">
            <div className="navbar contact_nav">
                <div className="container-fluid">
                    <div className="contact_flex">
                        <span className="flex_item_call">Call us: +233 20 814 0380</span>
                        <span className="flex_item_email">Email: alshanagroltd@gmail.com</span>
                    </div>
                </div>
            </div>
            <nav className="navbar navbar-light navbar-expand-lg bg-white">
                <div className="container-fluid main_container"><a className="navbar-brand" href="#header"><img src={mainLogo} className="alshanLogo" alt=""/></a>
                    <button data-toggle="collapse" className="navbar-toggler collapsed" data-target="#navcol-1"><span></span><span></span><span></span></button>
                    <div className="collapse navbar-collapse" id="navcol-1">
                        <ul className="nav navbar-nav ml-auto navbar_items_container">
                            <li className="nav-item" role="presentation"><a className="nav-link" href="#about">About Us</a></li>
                            <li className="nav-item" role="presentation"><a className="nav-link" href="#products">Our Products</a></li>
                            <li className="nav-item" role="presentation"><a className="nav-link" href="#whyus">Why Us</a></li>
                            <li className="nav-item" role="presentation"><a className="nav-link" href="#team">Our Team</a></li>
                            <li className="nav-item" role="presentation"><a className="nav-link" href="#gallery">Gallery</a></li>
                        </ul>

                        <div className="small_info">
                            <hr/>
                            <p>Call us: +233 20 814 0380</p>
                            <p>Email: alshanagroltd@gmail.com</p>
                        </div>
                    </div>
                </div>
            </nav>
        </div>

        </Fragment>

        )

}


export default Nav;