import React from "react";
import "../css/about.css";
import CoreValues from "./CoreValues";
import left_flower from "../img/Flower.png";
import right_flower from "../img/Flower_right.png";
// import large_flower from "../img/Large_flower.png";
import about_img from "../img/about_img.JPG";

function About(){

    return(
        <section id="about" className="anchor py-5">

            <div className="container-fluid about_container">
                <div className="container-fluid about_us_main">
                    <div className="row text-center abt">
                        <div className="col-md-6">
                            {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                            <img src={about_img} className="about_us_img" alt="About Us image"/>
                        </div>

                        <div className="col-md-6 abt-info">
                            <div className="abt-heading">
                                <h1 className="text-left">About Us</h1>

                                <h2>A Ghanaian owned agro-waste company</h2>
                            </div>
                            <div className="">
                                <p className="p-info">Meet ALSHAN Agro: Where Ghanaian expertise fuels innovation! Established in December 2022, we're a dynamic small
                                                      business based in Akosombo, Ghana. Our passion? Transforming agricultural by-products into valuable assets for
                                                      farming and industry.</p>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="container-fluid mission_container">
                    <div className="row abt-mission">
                        <img src={left_flower} className="left_f" alt="left flower"/>
                        <img src={right_flower} className="right_f" alt="right flower"/>

                        <div className="col-md-6 mission_info">

                            <h3>Our Mission</h3>
                            <p className="m-info">Our mission at ALSHAN is to produce valuable resources for agricultural and industrial purposes using agricultural by-products such as grinded cocoa bean shells and dried palm kernel shells.</p>
                        </div>
                        <div className="col-md-6 mission_info">
                            <h3>Our Vision</h3>
                            <p className="v-info">We strive to be the leading supplier of value added agro by-products in West Africa.</p>
                        </div>
                    </div>
                </div>

                <CoreValues/>

            </div>



        </section>
    )
}


export default About;